import React, { useRef, useEffect } from 'react';
import { graphql, Link } from 'gatsby';

import Container from '@components/Container';
import Layout from '@layouts/business';
import SEO from '@components/seo';

import styles from './styles.module.css';

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const ref = useRef();

  useEffect(() => {
    [...ref.current.querySelectorAll('p')].map(node =>
      node.classList.add(styles.paragraph)
    );

    [...ref.current.querySelectorAll('img')].map(node =>
      node.classList.add(styles.img)
    );
  });

  return (
    <Layout>
      <SEO title="kek" />
      <main className={styles.container}>
        <Container>
          <Link className={styles.back} to={'/blog'}>
            {'Блог'}
          </Link>

          <div className="blog-post-container">
            <div className="blog-post">
              <h1 className={styles.title}>{frontmatter.title}</h1>
              <small className={styles.date}>{frontmatter.date}</small>
              <div
                ref={ref}
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </Container>
      </main>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
